import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading/Loading';

import { getAllOrdersDetailsUser } from '../APIS';

function createData(orderId, orderDate, total) {
  return { orderId, orderDate, total };
}

const rows = [
  createData(1, '2024-6-10', 6.0),
  createData(2, '2024-6-10', 9.0),
  createData(3, '2024-6-10', 16.0),
  createData(4, '2024-6-10', 3.7),
  createData(5, '2024-6-10', 16.0),
];

export default function LastOrder() {
  const [lastOrders, setLastOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClick = (orderId) => {
    navigate(`/lastorders/${orderId}`); // Use navigate for programmatic redirection
  };

  const getLastOrders = async () => {
    try {
      setLoading(true);
      const response = await getAllOrdersDetailsUser();
      setLoading(false);
      setLastOrders(response);
    } catch (error) {
      throw error;
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0-11
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([
        { label: 'الرئيسية', path: '/' },
        {
          label: 'اخر الطلبيات',
          path: '/lastorders',
        },
      ])
    );
    getLastOrders();
    window.dispatchEvent(new Event('storage'));
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {' '}
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, direction: 'rtl' }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell align='right'>رقم الطلبية</TableCell>
                  <TableCell align='right'>تاريخ الطلبية</TableCell>
                  <TableCell align='right'> المجموع الكلي</TableCell>
                  <TableCell align='right'>تفاصيل الطلبية</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lastOrders.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align='right'>{row.orderNumber}</TableCell>
                    <TableCell align='right'>
                      {formatDate(row.date_added)}
                    </TableCell>
                    <TableCell align='right'>{row.totalAmount}</TableCell>
                    <TableCell align='right'>
                      <Button onClick={() => handleClick(row.orderNumber)}>
                        تفاصيل الطلبية
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
