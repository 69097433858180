import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';
import Loading from './Loading/Loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import {
  getUserInfo,
  getCities,
  editUserInfo,
  changePasswordCustomer,
} from '../APIS';

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [cities, setCities] = useState([]);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [city, setCity] = useState(0);
  const [cityName, setCityName] = useState('');
  const [address, setAddress] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([
        { label: 'الرئيسية', path: '/' },
        {
          label: 'الملف الشخصي',
          path: '/profile',
        },
      ])
    );
    window.dispatchEvent(new Event('storage'));
  }, []);

  const handleSaveInformation = async () => {
    const object = {
      name,
      mobile,
      city,
      address,
    };
    try {
      const response = await editUserInfo(object);

      if (response.status !== 'success') {
        toast.error(response.msg);
      } else {
        toast.success(response.msg);
        fetchUserInfo();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleChangePasswordButton = async () => {
    if (oldPassword && newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        setLoading(true);
        const response = await changePasswordCustomer(oldPassword, newPassword);
        setLoading(false);
        if (response.status === 'success') {
          toast.success(response.msg);
          navigate('/');
        } else {
          toast.error(response.msg);
        }
      } else {
        toast.error('الرجاء التأكد من التطابق');
      }
    } else {
      toast.error('الرجاء ادخال كافة الحقول');
    }
  };

  const fetchUserInfo = async () => {
    try {
      setLoading(true);
      const response = await getUserInfo();
      setLoading(false);

      if (response.status === 'success') {
        setUser(response.data[0]);
        setName(response.data[0].name);
        setMobile(response.data[0].mobile);

        setCity(response.data[0].city);

        setAddress(response.data[0].address);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        setLoading(true);
        const response = await getCities();
        setLoading(false);
        if (response.status === 'success') {
          setCities(response.data);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchCities();

    fetchUserInfo();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            flex: 0.4,
            backgroundColor: '#cccccc99',
            borderRadius: '10px',
            margin: 10,
            paddingLeft: 30,
            paddingRight: 30,
            height: '460px',
            position: isMobile ? '' : 'sticky',
            top: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>معلومات التوصيل</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              الاسم
            </p>
            <input
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              رقم الهاتف
            </p>
            <input
              type='tel'
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              المدينة
            </p>
            <select
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
                backgroundColor: '#fff',
                color: '#000',
                direction: 'rtl',
              }}
            >
              {cities.map((city) => (
                <option
                  key={city.id}
                  value={city.id}
                  style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    paddingRight: '10px',
                  }}
                >
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              العنوان
            </p>
            <input
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            />
          </div>

          <div
            onClick={() => handleSaveInformation({})}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div
              style={{
                width: '10%',
                borderRadius: '5px',
                backgroundColor: '#3C7BBE',
                fontWeight: 'bold',
                marginRight: '10px',
                paddingLeft: '10px',
                marginTop: '10px',
                marginBottom: '10px',
                paddingRight: '10px',
                cursor: 'pointer',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              حفظ
            </div>
          </div>

          <div
            style={{ width: '100%', backgroundColor: 'gray', height: '1px' }}
          ></div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>كلمة المرور</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                width: '130px',
                marginLeft: isMobile ? '5px' : '10px',
                fontSize: '15px',
              }}
            >
              كلمة المرور القديمة
            </p>
            <input
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: isMobile ? '5px' : '10px',
                fontSize: '15px',
                width: '130px',
              }}
            >
              كلمة المرور الجديدة
            </p>

            <input
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                width: '130px',
                marginLeft: isMobile ? '5px' : '10px',
                textAlign: 'right',
                fontSize: '15px',
              }}
            >
              تأكيد كلمة المرور
            </p>
            <input
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            onClick={() => handleChangePasswordButton()}
          >
            <div
              style={{
                width: '10%',
                borderRadius: '5px',
                backgroundColor: '#3C7BBE',
                fontWeight: 'bold',
                marginRight: '10px',
                paddingLeft: '10px',
                marginTop: '10px',
                marginBottom: '10px',
                paddingRight: '10px',
                cursor: 'pointer',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              حفظ
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
