import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { isTablet } from '../deviceUtils';
import { editCartQuantity, deleteFromCart } from '../APIS';

const ProductCardCart = ({ product, products, setProducts, fetchCart }) => {
  const [count, setCount] = useState(product.quantity);
  const navigate = useNavigate();
  const cardStyle = {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.7fr 0.2fr 0.2fr', // Adjust the number of columns as needed
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '10px',
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    direction: 'rtl',
    gridAutoRows: 'auto', // Set each row's height to adjust automatically based on content
    // maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const imageStyle = {
    // backgroundColor: 'yellow',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    textAlign: 'right',
  };

  const titleStyle = {
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'right', // Right-align the title
    cursor: 'pointer',
  };

  const textStyle = {
    margin: '5px',
    textAlign: 'right', // Right-align text content
    fontSize: '12px',
    marginTop: -10,
    marginRight: 0,
    color: '#ccc',
    fontWeight: 'bold',
  };

  const buttonStyle = {};

  const buttonContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '4px',
  };

  const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 10,
    height: 10,
    padding: '8px',
    fontSize: '1.2rem',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#3C7BBE',
  };

  const countText = {
    fontSize: '1rem',
    margin: '4px',
  };

  const handleDeleteProductFromCart = async () => {
    setCount(0);
    try {
      const response = await deleteFromCart(product.id);

      if (response.status === 'success') {
        fetchCart();
      }
    } catch (error) {
      throw error;
    }
  };

  const incrementCount = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };
  const updateItem = (id) => {
    setProducts((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: count } : item
      )
    );
  };
  const handleEditQuantityButton = async () => {
    updateItem(product.id);

    try {
      const response = await editCartQuantity({
        quantity: count,
        cartId: product.id,
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    handleEditQuantityButton();
  }, [count]);

  return (
    <div>
      <div key={product.id} style={cardStyle}>
        <div style={imageStyle}>
          <img
            onClick={() => {
              navigate(`/product/${product.productId}`);
            }}
            style={{
              objectFit: 'cover',
              width: '100%',
              aspectRatio: 1 / 1,
              maxWidth: '100px',
              cursor: 'pointer',
            }}
            src={product.image} // Use product imageUrl
            alt={product.name}
          />
        </div>

        <div style={contentStyle}>
          <h5
            style={titleStyle}
            onClick={() => {
              navigate(`/product/${product.productId}`);
            }}
          >
            {product.name}
          </h5>
          <p style={textStyle}>{product.category}</p>
          <p
            style={{
              marginTop: 0,
              fontSize: '12px',
              marginRight: 0,
              color: '#cc0000',
              fontWeight: 'bold',
            }}
          >
            ₪{product.price.toFixed(2)}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {/* <p style={textStyle}>الكمية</p> */}

          <div style={buttonContainer}>
            <div style={button} onClick={incrementCount}>
              <p
                style={{
                  display: 'flex',
                  paddingTop: 'auto',
                  paddingBottom: 'auto',
                }}
              >
                <AddIcon sx={{ color: 'white', fontSize: 20 }} />
              </p>
            </div>
            <div style={countText}>{count}</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
                width: 10,
                height: 10,
                padding: '8px',
                fontSize: '1.2rem',
                cursor: 'pointer',
                borderRadius: '50%',
                border: '1px solid #3C7BBE',
              }}
              onClick={decrementCount}
            >
              <RemoveIcon
                sx={{
                  color: '#3C7BBE',
                  fontSize: 20,
                  cursor: count == 0 ? 'not-allowed' : 'pointer',
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
          onClick={() => {
            handleDeleteProductFromCart();
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 'auto',
              marginBottom: 'auto',
              width: 10,
              height: 10,
              padding: '8px',
              fontSize: '1.2rem',
              cursor: 'pointer',
              borderRadius: '50%',
              border: '1px solid #cc0000',
            }}
          >
            <ClearIcon sx={{ color: '#cc0000', fontSize: 20 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardCart;
