import React, { useState, useEffect } from 'react';
import PlusMinusButton from './PlusMinusButton';
import { isMobile } from 'react-device-detect';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import ProductCardForOrderDetails from './ProductCardForOrderDetails';
import { useParams } from 'react-router-dom';
import { isTablet } from '../deviceUtils';

import { getOrderDetailsUser } from '../APIS';

const OrderDetails = () => {
  let { orderId } = useParams();

  const [details, setDetails] = useState({});
  const [cartProducts, setCartProducts] = useState([]);

  const getDetails = async () => {
    try {
      const response = await getOrderDetailsUser(orderId);
      setDetails(response);
      setCartProducts(response.cart);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([
        { label: 'الرئيسية', path: '/' },
        {
          label: 'اخر الطلبيات',
          path: '/lastorders',
        },
        {
          label: orderId,
          path: '/lastorders/' + orderId,
        },
      ])
    );
    getDetails();
    window.dispatchEvent(new Event('storage'));
  }, []);

  // const products = [
  //   {
  //     id: 1,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 1',
  //     category: '1 كاسات',
  //     price: 10.99,
  //     quantity: 2,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },
  //   {
  //     id: 2,
  //     name: 'طقم كاسات زجاج 6 كاسات ملون',
  //     description: 'Description for Product 2',
  //     category: '2كاسات',
  //     price: 20.99,
  //     quantity: 1,
  //     imageUrl:
  //       'https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1705905280/Croma%20Assets/Small%20Appliances/Food%20Processors/Images/260703_0_mx183g.png?tr=w-400',
  //   },

  // ];

  const cardStyle = {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.7fr 0.2fr 0.2fr', // Adjust the number of columns as needed
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '10px',
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    direction: 'rtl',
    gridAutoRows: 'auto', // Set each row's height to adjust automatically based on content
    // maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const imageStyle = {
    // backgroundColor: 'yellow',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    textAlign: 'right',
  };

  const titleStyle = {
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'right', // Right-align the title
  };

  const textStyle = {
    margin: '5px',
    textAlign: 'right', // Right-align text content
    fontSize: '12px',
    marginTop: -10,
    marginRight: 0,
    color: '#ccc',
    fontWeight: 'bold',
  };

  const buttonStyle = {};

  const buttonContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '4px',
  };

  const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 10,
    height: 10,
    padding: '8px',
    fontSize: '1.2rem',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#3C7BBE',
  };

  const countText = {
    fontSize: '1rem',
    margin: '4px',
  };

  return (
    <div
      style={{
        display: isMobile ? 'block' : 'flex',
        flexDirection: 'row',
        marginLeft: '5px',
        marginRight: '5px',
      }}
    >
      <div
        style={{
          flex: 0.6,
        }}
      >
        <div>
          {cartProducts.map((product) => (
            <ProductCardForOrderDetails key={product.id} product={product} />
          ))}
        </div>
      </div>
      <div
        style={{
          flex: 0.4,
        }}
      >
        <div
          style={{
            backgroundColor: '#cccccc99',
            borderRadius: '10px',
            margin: 10,
            paddingLeft: 30,
            paddingRight: 30,
            position: isMobile ? '' : 'sticky',
            top: '10px',
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>معلومات التوصيل</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              الاسم
            </p>
            <label
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            >
              {details.customerName}{' '}
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              رقم الهاتف
            </p>
            <label
              type='tel'
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            >
              {details.mobile}{' '}
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              المدينة
            </p>
            <label
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
                color: '#000',
                direction: 'rtl',
              }}
            >
              {details.city}
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                minWidth: '100px',
                textAlign: 'right',
                marginLeft: '10px',
              }}
            >
              العنوان
            </p>
            <label
              style={{
                borderRadius: '10px',
                border: 'none',
                flex: 1,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
                outline: 'none',
              }}
            >
              {details.address}
            </label>
          </div>

          <div
            style={{ width: '100%', backgroundColor: 'gray', height: '1px' }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
            }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>ملخص الطلبية</p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>مجموع الطلبية</p>
            <p>₪ {details.subtotalAmount} </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>الخصم</p>
            <p>₪ {details.discount} </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>التوصيل</p>
            <p>₪ {details.shipping} </p>
          </div>
          <div
            style={{ width: '100%', backgroundColor: 'gray', height: '1px' }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>المجموع الكلي</p>
            <p style={{ fontWeight: 'bold' }}>₪ {details.totalAmount} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
