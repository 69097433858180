import React, { useContext, useState, useRef, useEffect } from 'react';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';

import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { FavoriteBorder } from '@mui/icons-material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../../deviceUtils';

import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Logo';
import bag from '../../assets/images/bag.png';
import wishlistBold from '../../assets/images/wishlistBold.png';
import { useNavigate } from 'react-router-dom';
import MyContext from '../../contexts/MyContext';
import Breadcrumb from '../BreadCrumb/BreadCrumb';
import SearchInput from '../SearchInput';
import LoginModal from '../LoginModal';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import {
  AppBar,
  Toolbar,
  TextField,
  Typography,
  IconButton,
  Box,
  Input,
} from '@mui/material';

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [liveSearchOpen, setLiveSearchOpen] = useState(false);
  const [openDropDownListProfile, setOpenDropDownListProfile] = useState(false);

  const { productsInCart, setProductsInCart } = useContext(MyContext);
  const { productsInWishlist, setProductsInWishlist } = useContext(MyContext);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropDownListProfile(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  function MouseOver(event) {
    event.target.style.background = '#eee';
  }
  function MouseOut(event) {
    event.target.style.background = '';
  }
  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '11px',
          flexDirection: 'row',
          width: isMobile ? '97%' : '70%',
          marginLeft: 'auto',
          marginRight: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Logo />
        <div
          style={{
            position: 'relative',
            flex: isMobile ? 1 : 0.7,
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <div
            style={{
              border: '1px solid #ccc', // Set border to 1px solid color
              borderRadius: '8px', // Set border radius to 8px
              height: '40px',

              display: 'flex',
              padding: '5px',
              borderBottomLeftRadius: liveSearchOpen ? 0 : 8,
              borderBottomRightRadius: liveSearchOpen ? 0 : 8,
            }}
          >
            <IconButton
              type='submit'
              aria-label='search'
              onClick={() => {
                navigate('/search?type=search&que=' + searchTerm);
              }}
            >
              <SearchIcon />
            </IconButton>
            <SearchInput setSearchTerm={setSearchTerm} />
          </div>
          {liveSearchOpen && (
            <div
              style={{
                position: 'absolute',
                zIndex: 99,
                width: 'calc(100% - 2px)',

                border: 'solid 1px',
                backgroundColor: '#fff',
                borderColor: '#ccc',
                borderRadius: 10,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTopWidth: 0,
              }}
            >
              <p>test</p>
              <p>test</p>
              <p>test</p>
              <p>test</p>
              <p>test</p>
              <p>test</p>
            </div>
          )}
        </div>
        {isMobile && !localStorage.getItem('token') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <LoginModal />
          </div>
        )}

        {isMobile && localStorage.getItem('token') && (
          <div
            style={{
              position: 'relative',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '10px',
            }}
          >
            <PersonOutlineSharpIcon
              onClick={() => {
                setOpenDropDownListProfile(!openDropDownListProfile);
              }}
              sx={{
                width: '40px',
                height: '40px',
                color: '#3C7BBE',
                cursor: 'pointer',
                marginTop: '10px',
              }}
            />
            {openDropDownListProfile && (
              <div
                ref={wrapperRef}
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alginItems: 'center',
                  zIndex: 999,
                  border: '1px solid #3C7BBE',
                  borderRadius: '5px',
                  width: '150px',
                  margin: '5px',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  left: 0,
                  marginTop: '10px',
                }}
              >
                <div
                  onClick={() => {
                    navigate('/profile');
                    setOpenDropDownListProfile(false);
                  }}
                  onMouseOver={MouseOver}
                  onMouseOut={MouseOut}
                >
                  <p
                    style={{
                      textAlign: 'right',
                      paddingRight: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    حسابي
                  </p>
                </div>
                <div
                  onClick={() => {
                    navigate('/lastorders');
                    setOpenDropDownListProfile(false);
                  }}
                  onMouseOver={MouseOver}
                  onMouseOut={MouseOut}
                >
                  <p
                    style={{
                      textAlign: 'right',
                      paddingRight: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    الطلبيات السابقة
                  </p>
                </div>
                <div
                  onMouseOver={MouseOver}
                  onMouseOut={MouseOut}
                  onClick={() => {
                    localStorage.removeItem('cartId');

                    localStorage.removeItem('token');
                    navigate('/');
                    window.location.reload();
                  }}
                >
                  <p
                    style={{
                      textAlign: 'right',
                      paddingRight: 5,
                      fontWeight: 'bold',
                      color: '#cc0000',
                    }}
                  >
                    تسجيل الخروج
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        <Box
          sx={{
            padding: '5px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isMobile && (
            <>
              {/* <div style={{ position: 'relative' }}> */}

              <Stack spacing={3} direction='row'>
                <Badge color='secondary' badgeContent={productsInCart}>
                  <ShoppingBagOutlinedIcon
                    src={bag}
                    icon={faShoppingBag}
                    onClick={() => {
                      navigate('/cart');
                    }}
                    style={{
                      width: '40px',
                      height: '40px',
                      margin: '10px',
                      cursor: 'pointer',
                      color: '#3C7BBE',
                    }}
                  />
                </Badge>
              </Stack>

              {localStorage.getItem('token') && (
                <Badge color='secondary' badgeContent={productsInWishlist}>
                  <FavoriteBorder
                    // style={{ width: 30, height: 30 }}
                    src={wishlistBold}
                    onClick={() => {
                      navigate('/wishlist');
                    }}
                    style={{
                      width: '40px',
                      height: '40px',
                      margin: '10px',
                      cursor: 'pointer',
                      color: '#3C7BBE',
                    }}
                  />
                </Badge>
              )}
              {!localStorage.getItem('token') && <LoginModal />}
              {localStorage.getItem('token') && (
                <div style={{ position: 'relative' }}>
                  <PersonOutlineSharpIcon
                    sx={{
                      width: '40px',
                      height: '40px',
                      color: '#3C7BBE',
                      cursor: 'pointer',
                      marginTop: '10px',
                    }}
                    onClick={() => {
                      setOpenDropDownListProfile(!openDropDownListProfile);
                    }}
                  />
                  {openDropDownListProfile && (
                    <div
                      ref={wrapperRef}
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alginItems: 'center',
                        zIndex: 999,
                        border: '1px solid #3C7BBE',
                        borderRadius: '5px',
                        width: '150px',
                        margin: '5px',
                        backgroundColor: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        onClick={() => {
                          navigate('/profile');
                          setOpenDropDownListProfile(false);
                        }}
                        onMouseOver={MouseOver}
                        onMouseOut={MouseOut}
                      >
                        <p
                          style={{
                            textAlign: 'right',
                            paddingRight: 5,
                            fontWeight: 'bold',
                          }}
                        >
                          حسابي
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          navigate('/lastorders');
                          setOpenDropDownListProfile(false);
                        }}
                        onMouseOver={MouseOver}
                        onMouseOut={MouseOut}
                      >
                        <p
                          style={{
                            textAlign: 'right',
                            paddingRight: 5,
                            fontWeight: 'bold',
                          }}
                        >
                          الطلبيات السابقة
                        </p>
                      </div>
                      <div
                        onMouseOver={MouseOver}
                        onMouseOut={MouseOut}
                        onClick={() => {
                          localStorage.removeItem('token');
                          localStorage.removeItem('cartId');

                          window.location.reload();
                        }}
                        saqZA
                      >
                        <p
                          style={{
                            textAlign: 'right',
                            paddingRight: 5,
                            fontWeight: 'bold',
                            color: '#cc0000',
                          }}
                        >
                          تسجيل الخروج
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Box>
      </Box>
      <div
        style={{
          width: '100%',
          height: '4px',
          backgroundColor: '#3C7BBE',
          position: 'static',
          zIndex: 1000,
        }}
      ></div>

      <Breadcrumb
        items={[
          { label: 'الرئيسية', path: '/' },
          { label: 'كاسات', path: '/search' },
          { label: 'طقم كاسات 6 قطع', path: '/product' },
        ]}
      />
    </>
  );
};

export default Header;
