import React, { useState } from 'react';
import MyContext from '../contexts/MyContext';

const MyProvider = ({ children }) => {
  const [productsInCart, setProductsInCart] = useState(0);
  const [productsInWishlist, setProductsInWishlist] = useState(0);

  const contextValue = {
    productsInCart,
    setProductsInCart,
    productsInWishlist,
    setProductsInWishlist,
  };

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
};

export default MyProvider;
