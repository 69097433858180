import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';

import ProductCard from './ProductCard';
import Loading from './Loading/Loading';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { getWishList } from '../APIS';

const WishList = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const fetchProductsFromWishList = async () => {
    try {
      setLoading(true);
      const response = await getWishList();
      setLoading(false);
      setProducts(response.result);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([
        { label: 'الرئيسية', path: '/' },
        {
          label: 'المفضلة',
          path: '/wishlist',
        },
      ])
    );
    window.dispatchEvent(new Event('storage'));

    fetchProductsFromWishList();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              // height: '100%',
            }}
          >
            {products.length > 0 ? (
              <div
                style={{
                  direction: 'rtl',
                  padding: 10,
                  marginTop: 10,
                  borderRadius: 10,
                  borderColor: '#00000011',
                  display: 'grid',
                  gridTemplateColumns: isMobile
                    ? 'repeat(2, 1fr)'
                    : 'repeat(5, 1fr)',
                  gap: 10,
                }}
              >
                {products.map((product, index) => (
                  <ProductCard product={product} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                  width: '100%',
                  height: '300px',
                }}
              >
                <p style={{ fontWeight: 'bold' }}>لا يوجد منتجات مفضلة</p>
              </div>
            )}
            :
          </div>
        </>
      )}
    </>
  );
};

export default WishList;
