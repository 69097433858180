import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';

import ProductCard from './ProductCard';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useLocation, useParams } from 'react-router-dom';
import { getProductsByCategory, searchProducts } from '../APIS';
import AnimatedIcon from './AnimatedIcon/AnimatedIcon';

import Skeleton from '@mui/material/Skeleton';
import useIntersectionObserver from './IsDivVisibile';
const Search = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [page, setPage] = useState(1);
  const observerRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get('type');
  const location = useLocation();
  const [isVisible, elementRef] = useIntersectionObserver({
    root: null,
    rootMargin: '1px',
    threshold: 0.1,
  });

  useEffect(() => {
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([
        { label: 'الرئيسية', path: '/' },
        {
          label: 'البحث',
          path: window.location.href.substring(
            window.location.href.lastIndexOf('/') + 1
          ),
        },
      ])
    );
    window.dispatchEvent(new Event('storage'));
  }, []);
  const loadMoreProducts = () => {
    if (page * 20 < products.length) {
      setDisplayedProducts((prev) => [
        ...prev,
        ...products.slice(page * 20, (page + 1) * 20),
      ]);
      setPage((prev) => prev + 1);
    } else if (
      page * 20 >= products.length &&
      page * 20 < products.length + 20
    ) {
      setDisplayedProducts(products);
      setPage((prev) => prev + 1);
    }
  };

  const sortProductsByNameAscending = () => {
    const sortedProducts = [...products].sort((a, b) =>
      a.name.localeCompare(b.name, 'ar')
    );
    setProducts(sortedProducts);
    setDisplayedProducts(sortedProducts.slice(0, 20));
    setPage(1);
  };

  const sortProductsByNameDescending = () => {
    const sortedProducts = [...products].sort((a, b) =>
      b.name.localeCompare(a.name, 'ar')
    );
    setProducts(sortedProducts);
    setDisplayedProducts(sortedProducts.slice(0, 20));
    setPage(1);
  };

  const sortProductsByPriceAscending = () => {
    const sortedProducts = [...products].sort((a, b) => a.price - b.price);
    setProducts(sortedProducts);
    setDisplayedProducts(sortedProducts.slice(0, 20));
    setPage(1);
  };

  const sortProductsByPriceDescending = () => {
    const sortedProducts = [...products].sort((a, b) => b.price - a.price);
    setProducts(sortedProducts);
    setDisplayedProducts(sortedProducts.slice(0, 20));
    setPage(1);
  };

  const sortByDateAscending = () => {
    const sortedProducts = [...products].sort((a, b) => a.id - b.id);
    setProducts(sortedProducts);
    setDisplayedProducts(sortedProducts.slice(0, 20));
    setPage(1);
  };

  const sortByDateDescending = () => {
    const sortedProducts = [...products].sort((a, b) => b.id - a.id);
    setProducts(sortedProducts);
    setDisplayedProducts(sortedProducts.slice(0, 20));
    setPage(1);
  };

  const handleSortChange = (e) => {
    const sortValue = parseInt(e.target.value, 20);
    switch (sortValue) {
      case 0:
        sortProductsByNameAscending();
        break;
      case 1:
        sortProductsByNameDescending();
        break;
      case 2:
        sortProductsByPriceAscending();
        break;
      case 3:
        sortProductsByPriceDescending();
        break;
      case 4:
        sortByDateAscending();
        break;
      case 5:
        sortByDateDescending();
        break;
      default:
        break;
    }
  };

  const fetchProductsFilteredByCategory = async () => {
    try {
      setLoading(true);
      const response = await getProductsByCategory(
        queryParams.get('categoryId')
      );
      setLoading(false);

      if (response.status === 'success') {
        setProducts(response.data);
        setDisplayedProducts(response.data.slice(0, 20));
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchProductsFilteredBySearch = async () => {
    try {
      setLoading(true);
      const response = await searchProducts(queryParams.get('que'));
      setLoading(false);

      if (response.status === 'success') {
        setProducts(response.data);
        setDisplayedProducts(response.data.slice(0, 20));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleVisibilityChange = () => {
    loadMoreProducts();
    // Call your function here
  };

  useEffect(() => {
    console.log(isVisible);
    if (isVisible) {
      handleVisibilityChange();
    }
  }, [isVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProducts([]);
    setDisplayedProducts([]);
    if (type === 'category') {
      fetchProductsFilteredByCategory();
    } else if (type == 'search') {
      fetchProductsFilteredBySearch();
    }
  }, [location.search]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
          }}
        >
          <select
            onChange={handleSortChange}
            style={{
              borderRadius: '10px',
              border: 'none',
              textAlign: 'right',
              fontWeight: 'bold',
              paddingRight: '10px',
              outline: 'none',
              backgroundColor: '#fff',
              color: '#000',
              direction: 'rtl',
              border: 'solid 1px #ccc',
              height: '29px',
            }}
          >
            <option
              value={8}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب
            </option>
            <option
              value={0}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب الاسم تصاعديا
            </option>
            <option
              value={1}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب الاسم تنازليا
            </option>
            <option
              value={2}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب السعر تصاعديا
            </option>
            <option
              value={3}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب السعر تنازليا
            </option>
            <option
              value={4}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب تاريخ الاضافة تصاعديا
            </option>
            <option
              value={5}
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: '10px',
              }}
            >
              ترتيب حسب تاريخ الاضافة تنازليا
            </option>
          </select>
          <div
            style={{
              display: 'flex',
            }}
          >
            <FilterAltOutlinedIcon
              style={{
                color: '#aaa',
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ fontWeight: 'bold' }}> عدد النتائج</p>
          <p style={{ marginRight: '10px', fontWeight: 'bold' }}>
            {products.length}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <div
            style={{
              direction: 'rtl',
              padding: 10,
              marginTop: 10,
              borderRadius: 10,
              borderColor: '#00000011',
              display: 'grid',
              gridTemplateColumns: isMobile
                ? 'repeat(2, 1fr)'
                : 'repeat(5, 1fr)',
              gap: 10,
            }}
          >
            {' '}
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
            <Skeleton variant='rectangular' width={150} height={250} />
          </div>
        ) : (
          <>
            {displayedProducts.length > 0 ? (
              <div
                style={{
                  direction: 'rtl',
                  padding: 10,
                  marginTop: 10,
                  borderRadius: 10,
                  borderColor: '#00000011',
                  display: 'grid',
                  gridTemplateColumns: isMobile
                    ? 'repeat(2, 1fr)'
                    : 'repeat(5, 1fr)',
                  gap: 10,
                }}
              >
                {displayedProducts.map((product, index) => (
                  <ProductCard key={index} product={product} />
                ))}
              </div>
            ) : (
              <>
                {displayedProducts.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%',
                      height: '500px',
                    }}
                  >
                    <div>
                      <AnimatedIcon />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div ref={elementRef}></div>
    </>
  );
};

export default Search;
