import { useEffect } from 'react';

const useLocalStorageListener = (callback) => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      callback(event);
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [callback]);
};

export default useLocalStorageListener;
