import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';

import Skeleton from '@mui/material/Skeleton';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Navigation } from 'swiper/modules';

import CategoryCard from './CategoryCard';

const SwiperComponent = ({ categories }) => {
  return (
    <>
      <div
        style={{
          border: 'solid 1px #000',
          padding: 5,
          marginTop: 10,
          borderRadius: 10,
          backgroundColor: '#fff',
          borderColor: '#00000011',
          direction: 'rtl',
        }}
      >
        <Swiper
          slidesPerView={isMobile ? 3 : 6}
          spaceBetween={isMobile ? 2 : 15}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Navigation]}
        >
          {categories.length > 0 ? (
            categories.map((category, index) => {
              return (
                category.name && (
                  <SwiperSlide key={index}>
                    <CategoryCard category={category} />
                  </SwiperSlide>
                )
              );
            })
          ) : (
            <>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant='circular' width={60} height={60} />
              </SwiperSlide>
            </>
          )}
          {}
        </Swiper>
      </div>
    </>
  );
};

export default SwiperComponent;
