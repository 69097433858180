import React, { useState } from 'react';
import { Chip } from '@mui/material';

const PropertySelector = ({
  properties,
  selectedProperties,
  setSelectedProperties,
}) => {
  const handlePropertyClick = (property, value) => {
    setSelectedProperties((prev) => {
      const existingProperty = prev.find((p) => p.label === property);
      if (existingProperty) {
        if (existingProperty.value === value) {
          return prev.filter((p) => p.label !== property);
        } else {
          return prev.map((p) => (p.label === property ? { ...p, value } : p));
        }
      } else {
        return [...prev, { label: property, value }];
      }
    });
  };

  return (
    <div>
      {properties.map(({ label, values }) => (
        <div key={label} style={{ margin: '10px 0' }}>
          <p style={{ textAlign: 'right', fontWeight: 'bold' }}>{label}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {values.map((option) => (
              <Chip
                key={option}
                label={option}
                onClick={() => handlePropertyClick(label, option)}
                color={
                  selectedProperties.find(
                    (p) => p.label === label && p.value === option
                  )
                    ? 'primary'
                    : 'default'
                }
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertySelector;
