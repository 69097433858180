import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useTypingEffect = (
  texts,
  typingSpeed = 100,
  deletingSpeed = 50,
  delay = 1000
) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const handleTyping = () => {
      const currentText = texts[currentTextIndex];
      const updateText = isDeleting
        ? currentText.slice(0, displayedText.length - 1)
        : currentText.slice(0, displayedText.length + 1);
      setDisplayedText(updateText);

      if (!isDeleting && updateText === currentText) {
        setTimeout(() => setIsDeleting(true), delay);
      } else if (isDeleting && updateText === '') {
        setIsDeleting(false);
        setCurrentTextIndex((prev) => (prev + 1) % texts.length);
      }
    };

    const speed = isDeleting ? deletingSpeed : typingSpeed;
    const interval = setInterval(handleTyping, speed);
    return () => clearInterval(interval);
  }, [
    displayedText,
    isDeleting,
    texts,
    typingSpeed,
    deletingSpeed,
    delay,
    currentTextIndex,
  ]);

  return displayedText;
};

const SearchInput = ({ setSearchTerm }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const navigate = useNavigate();
  const typingText = useTypingEffect(
    [
      'ابحث عن أي منتج في متجرنا ...',
      'اكتشف العروض الجديدة!',
      'تسوق الآن للحصول على خصومات!',
    ],
    100, // Typing speed
    50, // Deleting speed
    1000 // Delay between typing and deleting
  );

  const handleChange = (event) => {
    setSearchTerm1(event.target.value);
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      navigate('/search?type=search&que=' + searchTerm1);
    }
  };

  return (
    <input
      type='text'
      placeholder={typingText}
      value={searchTerm1}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      style={{
        borderWidth: 0,
        outline: 'none',
        width: '100%',
        fontWeight: 'bold',
      }}
    />
  );
};

export default SearchInput;
