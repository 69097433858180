import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import MyProvider from './providers/MyProvider';
import { BrowserRouter } from 'react-router-dom';

const RootComponent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <>
      <React.StrictMode>
        <BrowserRouter>
          <MyProvider>
            <App />
          </MyProvider>
        </BrowserRouter>

        <div
          style={{
            zIndex: 9999999,
          }}
        >
          <ToastContainer position={isMobile ? 'top-center' : 'top-right'} />
        </div>
      </React.StrictMode>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
