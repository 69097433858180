import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { FavoriteBorder } from '@mui/icons-material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import MyContext from '../contexts/MyContext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
export default function BottomNavigationTabs() {
  const [value, setValue] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const { productsInCart } = useContext(MyContext);
  const { productsInWishlist } = useContext(MyContext);

  const navigate = useNavigate();

  const offset = 50; // The offset value to trigger visibility change

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(scrollTop - lastScrollTop) > offset) {
        if (scrollTop > lastScrollTop) {
          setIsVisible(false); // Scroll down
        } else {
          setIsVisible(true); // Scroll up
        }
        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile or negative scrolling
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop, offset]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: isVisible ? 0 : '-80px', // Move it out of view when not visible
        height: '70px',
        borderTop: 'solid 1px #ccc',
        backgroundColor: '#fff',
        zIndex: 999999999,
        transition: 'bottom 0.3s ease', // Smooth transition
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adding shadow at the top
      }}
    >
      <BottomNavigation
        sx={{ height: '100%' }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={() => {
            navigate('/');
          }}
          style={{}}
          icon={
            <HomeOutlinedIcon
              style={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
                color: '#3C7BBE',
              }}
            />
          }
          label='الرئيسية'
        />

        {localStorage.getItem('token') && (
          <BottomNavigationAction
            onClick={() => {
              navigate('/wishlist');
            }}
            style={{}}
            icon={
              <Badge
                color='secondary'
                badgeContent={productsInWishlist}
                overlap='circular'
              >
                <FavoriteBorder
                  style={{
                    width: '25px',
                    height: '25px',
                    cursor: 'pointer',
                    color: '#3C7BBE',
                  }}
                />
              </Badge>
            }
            label='المفضلة'
          />
        )}

        <BottomNavigationAction
          onClick={() => {
            navigate('/cart');
          }}
          icon={
            <Badge
              color='secondary'
              badgeContent={productsInCart}
              overlap='circular'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ShoppingBagOutlinedIcon
                icon={faShoppingBag}
                style={{
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                  color: '#3C7BBE',
                }}
              />
            </Badge>
          }
          label='سلة المشتريات'
        />
      </BottomNavigation>
    </Box>
  );
}
