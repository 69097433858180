import React from 'react';

import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ category }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        maxWidth: '345px',
        padding: '5px',
        margin: '10px',
        backgroundColor: '#f5f5f5',
        borderRadius: '16px',
        fontWeight: 'bold',
        transition: 'transform 0.3s',
        cursor: 'pointer',
        transform: 'scale(1)',
      }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
      onClick={() =>
        navigate('/search?type=category&categoryId=' + category.id)
      }
    >
      {category.name}
    </div>
  );
};

export default CategoryCard;
