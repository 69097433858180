import React from 'react';
import './CustomButton.css'; // Import the CSS file

const CustomButton = ({ text, onClick, component, inWishList = false }) => {
  return (
    <>
      {inWishList ? (
        <>
          {' '}
          <div className='custom-button-Wishlist ' onClick={onClick}>
            <div className='button-content'>
              {text && <p className='button-text'>{text}</p>}
              {component && <div className='icon-wishlist'>{component}</div>}
            </div>
          </div>
        </>
      ) : (
        <>
          {' '}
          <div className='custom-button' onClick={onClick}>
            <div className='button-content'>
              {text && <p className='button-text'>{text}</p>}
              {component && <div className='icon'>{component}</div>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomButton;
