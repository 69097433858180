import React from 'react';
import './AnimatedIcon.css';

const AnimatedIcon = () => {
  return (
    <div className='no-results'>
      <div className='icon'>
        <div className='face'>
          <div className='eyes'>
            <div className='eye'></div>
            <div className='eye'></div>
          </div>
          <div className='mouth'></div>
        </div>
        <div className='message'>
          <p>لا يوجد نتائج</p>
        </div>
      </div>
    </div>
  );
};

export default AnimatedIcon;
